import React from 'react'
import Button from '../common/Button'

export default function DownloadCTA({ title, description }) {
  return (
    <div className="flex flex-col">
      <div className="bg-uforange-600">
        <div className="mx-auto max-w-7xl px-6 py-8 lg:flex lg:items-center lg:justify-between lg:px-8">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            {title ? title : 'Download our sales brochure'}
          </h2>
        </div>
      </div>
      <div className="bg-ufblack">
        <div className="mx-auto max-w-7xl px-6 py-14 lg:pt-0 lg:flex lg:items-center lg:justify-between lg:px-8">
          <div className="flex flex-col space-y-12">
            <p className="max-w-lg text-lg mt-6 text-white">
              {description
                ? description
                : 'We help local authorities across the UK achieve electric mobility targets.'}
            </p>
            <Button label="Download sales brochure" href="/downloads" />
          </div>
          <div className="flex items-center gap-x-6 lg:flex-shrink-0 bg-white w-64 h-64 mt-12 mx-auto lg:mx-12 lg:-mt-20 rounded-full">
            <img
              src="/images/common/brochure.png"
              alt="Sales Brochure"
              className="object-fit"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
