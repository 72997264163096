import { Link } from 'gatsby'
import React from 'react'
import Button from './Button'
import Icon from './Icon'

export default function OfferItem({
  title,
  iconName,
  imgSrc,
  imgAlt,
  items,
  href,
}) {
  return (
    <Link className="w-full lg:w-1/2 flex flex-col cursor-pointer" to={href}>
      <div className="flex flex-row items-center gap-4 mb-8">
        <span className="flex h-12 w-12 items-center justify-center rounded bg-uforange-600">
          <Icon name={iconName} className={'w-6 h-6 text-white'} />
        </span>
        <h2 className="text-3xl font-bold tracking-tight text-gray-900">
          {title}
        </h2>
      </div>
      <img className="w-full rounded mb-12" src={imgSrc} alt={imgAlt} />
      <div className="flex flex-col gap-y-4 mb-12">
        {items.map((item) => (
          <div className="flex flex-row items-center gap-4">
            <Icon
              name={item.iconName}
              className={'w-6 h-6 text-uforange-600'}
            />
            <p className="text-lg tracking-tight text-gray-900">{item.title}</p>
          </div>
        ))}
      </div>
      <Button label="Learn more" href={href} />
    </Link>
  )
}
