import React from 'react'
import Header from '../components/common/Header'
import OfferItem from '../components/common/OfferItem'
import DownloadCTA from '../components/cta/DownloadCTA.js'
import GeneralCTA from '../components/cta/GeneralCTA.js'
import Layout from '../components/layout'
import Seo from '../components/seo'

class RootIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo
          title="What we offer"
          description="Innovative, retractable charging solutions like the UEone"
        />
        <Header filter="What we offer" />
        <div className="bg-white flex flex-col max-w-7xl mx-auto lg:flex-row py-32  px-6 md:px-20 gap-32">
          <OfferItem
            href="/what-we-offer/service-delivery"
            title={'Service Delivery'}
            iconName="BoltIcon"
            imgSrc="/images/common/service-delivery.png"
            imgAlt="Service delivery"
            items={[
              { title: 'Data driven network planning', iconName: 'MapPinIcon' },
              {
                title: 'Flexible funding solutions',
                iconName: 'BankNotesIcon',
              },
              {
                title: 'Operational and maintenance support',
                iconName: 'WrenchScrewdriverIcon',
              },
            ]}
          />
          <OfferItem
            href="/what-we-offer/commercial-model"
            title={'Commercial model'}
            iconName="CreditCardIcon"
            imgSrc="/images/common/partnerships.png"
            imgAlt="Our commercial model"
            items={[
              { title: 'Fully funded network', iconName: 'CurrencyPoundIcon' },
              {
                title: 'Flexible electricity procurement',
                iconName: 'BoltIcon',
              },
              {
                title: 'Fixed index-linked CPO revenue stream',
                iconName: 'PresentationChartLineIcon',
              },
            ]}
          />
        </div>
        <GeneralCTA />
        <DownloadCTA />
      </Layout>
    )
  }
}

export default RootIndex
